import firebase from "firebase";

const settings = {timestampsInSnapshots: true};

const config = {
    apiKey: "AIzaSyDIP69qL_KYTz7P4uXnWlI2TT7qbbQLmDQ",
    authDomain: "hollow-19bdd.firebaseapp.com",
    projectId: "hollow-19bdd",
    storageBucket: "hollow-19bdd.appspot.com",
    messagingSenderId: "831778341743",
    appId: "1:831778341743:web:46405ec7c938918809de9a"
};
firebase.initializeApp(config);
// firebase.functions().useEmulator("localhost", 5001);
firebase.firestore().settings(settings);

export default firebase;