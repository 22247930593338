import React, {useState, useEffect} from 'react';
import logo from './logo.png';
import hollowtipsLogo from './hollowtips.png';
import errorLogo from './error.png';
import authenticLogo from './checked.png';
import './App.css';
import firebase from './Firebase';
const publicIp = require('public-ip');


function SuccessMsg(){
  return(<h2><img alt={""} className='successImg' src={authenticLogo} />This is an authentic Hollowtips product</h2>)
}

function ErrorMsg(){
  return(<h2><img alt={""} className='successImg' src={errorLogo} />This serial number is invalid ❌</h2>)
}

function RateMsg(){
  return(<div><h2><img alt={""} className='successImg' src={errorLogo} />This serial number has <br />already been verified ❌</h2><p>This code has exceeded its view count</p></div>)
}


const DefaultMsg = () => (
  <div>
    <h2>Input serial number to verify if cartridge is authentic</h2>
    <div className="video-container"><iframe className="video" src="https://www.youtube.com/embed/346kp4Hxhk8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
  </div>)

function App() {
  const [data, setData] = useState('')
  const [formData, setFormData] = useState('')
  const [logData, setLogData] = useState([])
  const [ipAddress, setIpAddress] = useState('')

  const handleSerialNumberChange = (event) => {
    event.persist();
    setFormData(event.target.value);
  };
  

  const onSerialNumberSubmit = async (event) => {
    event.preventDefault()
    // console.log(formData)
    if(formData){
      try{
        const snapshot = await firebase.firestore().collection('codes').doc(formData)
        //check if serial number is valid
        const serialData = (await snapshot.get()).data()
        setData(true)

        //get logs from serial number
        const logData = [];
        snapshot.collection('logs').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              logData.push(doc.data())
          });
          setLogData(logData);
          addLogFunction();
        })
      } catch(err){
        // console.log(err)
        setData(false)
        setLogData([])
      }
    }
  }

  const cartStatus = () => {
    if(data == true){
      return <SuccessMsg />
    }else if (data.length == 0){
      return <DefaultMsg />;
    }else{
      return <ErrorMsg />
    }
  }

  const addLogFunction = () =>{
    const addLog = firebase.functions().httpsCallable('addLog')
    addLog({ip_address:ipAddress, docData:formData})
  }
  
  useEffect(() => {
    (async () => {
      setIpAddress(await publicIp.v4());
    })();
    },[])

  return (
    <>
      <div className="overlay"></div>
      <div className="App">
        <header className="App-header">
          <a href="https://hollowtips.com"><img src={logo} className="App-logo" alt="logo" /></a>
        </header>
        <div className="content">
          <form>
            <input type="text" placeholder="enter serial number" value={formData} onChange={handleSerialNumberChange} />
            <button onClick={onSerialNumberSubmit}>submit</button>
          </form>
        </div>
        <div className="status">
            {logData.length > 2 ? <RateMsg/> : cartStatus()}
            {/* {logData.map((test) => {
              // const date = new Date(test.time.toDate());
              // console.log(date)
              return <div>{test.ip_address}</div>
            })} */}
        </div>
      </div>
    </>
  );
}

export default App;

// this code has been used 3x times
// if this your time inputing please contact info@hollowtips.com